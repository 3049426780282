<script setup lang="ts">
import Button from './Button.vue';

type DirectionType = 'down' | 'up';
type Variant = 'primary' | 'secondary' | 'tertiary' | 'basic';

interface Props {
  /**
   * Unique component name
   */
  name: string;

  /**
   * Label displayed for the menu item
   */
  label?: string;

  /**
   * Direction in which the dropdown is going
   * @default down
   * @values up, down
   */
  direction?: DirectionType;

  /**
   * global class name
   */
  className?: string;

  /**
   * Icon displayed for the menu item
   */
  icon?: string;

  /**
   * Sitch design based on variant name
   */
  variant?: Variant;

  /**
   * Only icon is displayed
   */
  iconOnly?: boolean;

  /**
   * A class is added to remove the padding
   */
  fit?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  direction: 'down',
  icon: undefined,
  className: undefined,
  variant: 'primary',
  iconOnly: false,
  fit: false,
});

const openMenu = ref(false);
const menuId = `c-dropdown__menu_${props.name}`;
const buttonId = `c-dropdown__button_${props.name}`;

const toggleButton = (): void => {
  openMenu.value = !openMenu.value;
  if (openMenu.value === true) {
    const element = document.getElementById(menuId) as HTMLElement;
    element.focus();
  }
};

const handleFocusOut = (e: FocusEvent): void => {
  // if focusOut is triggered by the button click action
  // let the toggleButton method close the menu
  const target = e.relatedTarget as HTMLElement;
  if (openMenu.value && target?.id !== '' && target?.id !== buttonId) {
    openMenu.value = false;
  }

  if (target === null || target?.id === menuId) {
    openMenu.value = false;
  }
};

const closeMenu = (): void => { openMenu.value = false; };
</script>
<template>
  <div
    class="c-dropdown"
    :class="[className, { '-menu-open': openMenu, '-fit': fit }]"
  >
    <Button
      v-if="props.iconOnly"
      :id="buttonId"
      :icon="icon"
      icon-only
      :stroke-width="2"
      :variant="variant"
      @click="toggleButton"
    />
    <Button
      v-else
      :id="buttonId"
      :label="label"
      :icon="icon"
      :icon-right="`chevron-${direction}`"
      :variant="variant"
      @click="toggleButton"
    />
    <div
      :id="menuId"
      :class="['c-dropdown__menu', `c-dropdown__menu--${direction}`]"
      tabindex="0"
      @focusout="handleFocusOut"
    >
      <slot :close-menu="closeMenu" />
    </div>
  </div>
</template>
